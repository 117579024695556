import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {
    selectSelectedMemberData, getEditedMembershipDataStatus,
    setEditedMembershipDataStatus, setSelectedMemberData
} from "../../redux/slice/membershipSlice";
import {
    Button,
    Card,
    CardBody,
    CardFooter, Divider,
    Input,
    Select,
    SelectItem, Textarea,
    User
} from "@nextui-org/react";
import {
    DD_BLOOD_GROUP, DD_DESIGNATIONS, DD_GENDER,
    DD_MEMBERSHIP_STATUS,
    DD_PRANALI_ACCESS_FOR_PRES_SEC, DD_TERMINATION_REASON
} from "../../../constants/dropdownList";
import React from "react";
import {
    MEMBERSHIP_STATUS_FOR_DESIGNATIONS,
    NON_COUNCIL_ACCESS_LIST
} from "../../../constants/WebPageConstants";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {editMemberData} from "../../../services/MembershipService";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";

export default function EditMemberData() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedMemberData = useSelector(selectSelectedMemberData);
    const editedMembershipDataStatus = useSelector(getEditedMembershipDataStatus);

    const [isMembershipActive, setIsMembershipActive] = React.useState(selectedMemberData.membershipStatus === "Active");

    const [editedRotaryId, setEditedRotaryId] = React.useState(selectedMemberData?.rotaryId);
    const [editedMembershipStatus, setEditedMembershipStatus] = React.useState(selectedMemberData.membershipStatus);
    const [editedPranaliAccess, setEditedPranaliAccess] = React.useState(selectedMemberData.pranaliAccess);

    const [editedPrimaryContact, setEditedPrimaryContact] = React.useState(selectedMemberData.basicData.contact);
    const [editedSecondaryContact, setEditedSecondaryContact] = React.useState(selectedMemberData.basicData.contactSecondary);
    const [editedBloodGroup, setEditedBloodGroup] = React.useState(selectedMemberData.basicData.bloodGroup);
    const [editedDesignation, setEditedDesignation] = React.useState(new Set([...selectedMemberData.basicData.designation]));
    const [editedAddress, setEditedAddress] = React.useState(selectedMemberData.basicData.address);
    const [editedGender, setEditedGender] = React.useState(selectedMemberData.basicData.gender);
    const [editedProfileImageUrl, setEditedProfileImageUrl] = React.useState(selectedMemberData.basicData.profileImageUrl);

    const [memberTerminationReason, setMemberTerminationReason] = React.useState(null);
    const [memberTerminationExplanation, setMemberTerminationExplanation] = React.useState(null);

    const validateContactNumber = (value) => value.toString().match(/^[0-9]+$/) && value.toString().length === 10;
    const isPrimaryContactInvalid = React.useMemo(() => {
        if (null === editedPrimaryContact) return false;
        return !validateContactNumber(editedPrimaryContact);
    }, [editedPrimaryContact]);

    const isSecondaryContactInvalid = React.useMemo(() => {
        if (null === editedSecondaryContact || undefined === editedSecondaryContact || editedSecondaryContact.length === 0) return false;
        return !validateContactNumber(editedSecondaryContact);
    }, [editedSecondaryContact]);

    function updateMembershipStatus(e) {
        setIsMembershipActive(e.target.value === "Active");
        setEditedMembershipStatus(e.target.value);
    }

    async function submitForm(e) {
        e.preventDefault();

        let arrayOfDesignations = [];
        editedDesignation.forEach((designation) => {
            arrayOfDesignations.push(designation);
        });


        let editedData = {
            membershipId: selectedMemberData?.membershipId,
            rotaryId: editedRotaryId,
            membershipStatus: editedMembershipStatus,
            pranaliAccess: editedPranaliAccess,
            updateBasicData: {
                email: selectedMemberData.basicData.email,
                contact: editedPrimaryContact,
                contactSecondary: editedSecondaryContact,
                bloodGroup: editedBloodGroup,
                designation: arrayOfDesignations,
                address: editedAddress,
                gender: editedGender,
                profileImageUrl: editedProfileImageUrl
            },
            membershipTermination: {
                reason: memberTerminationReason,
                explanation: memberTerminationExplanation
            }
        }

        await editMemberData(dispatch, user, editedData, navigate)
    }

    function closeMemberEdit() {
        dispatch(setSelectedMemberData(null));
        dispatch(setEditedMembershipDataStatus('idle'));
        navigate("/pranali/membership");
    }

    return (
        <Card>
            <ToastContainer/>
            <form onSubmit={submitForm}>
                <CardBody>
                    <div className="flex flex-col col-span-full">
                        <div className="justify-start">
                            <User
                                name={<h1 className="text-large font-medium">{selectedMemberData.basicData.name}</h1>}
                                description={<p className="text-sm">{selectedMemberData.basicData.email}</p>}
                                avatarProps={{
                                    size: "lg",
                                    src: getImageFromDrive(editedProfileImageUrl)
                                }}
                            />
                            <Divider className="my-4 mb-4"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-12 gap-4 items-center">
                        <Select
                            isRequired
                            label="Membership Status"
                            defaultSelectedKeys={[editedMembershipStatus]}
                            variant="underlined"
                            className="max-w-md col-span-2 sm:col-span-4"
                            onChange={updateMembershipStatus}>
                            {DD_MEMBERSHIP_STATUS.map(data =>
                                <SelectItem key={data.label} value={data.value}>
                                    {data.label}
                                </SelectItem>
                            )}
                        </Select>

                        {(!selectedMemberData?.rotaryId && isMembershipActive) &&
                            <Input
                                type="text"
                                variant="underlined"
                                label="Rotary International Id"
                                value={editedRotaryId}
                                onValueChange={setEditedRotaryId}
                                className="max-w-md col-span-2 sm:col-span-4"
                            />
                        }
                        {(NON_COUNCIL_ACCESS_LIST.includes(selectedMemberData.pranaliAccess) && isMembershipActive) &&
                            <Select
                                isRequired
                                label="Pranali Access"
                                defaultSelectedKeys={[editedPranaliAccess]}
                                variant="underlined"
                                className="max-w-md col-span-2 sm:col-span-4"
                                onChange={e => setEditedPranaliAccess(e.target.value)}>
                                {DD_PRANALI_ACCESS_FOR_PRES_SEC.map(data =>
                                    <SelectItem key={data.value} value={data.value}>
                                        {data.label}
                                    </SelectItem>
                                )}
                            </Select>
                        }
                        <Input
                            isRequired
                            type="text"
                            variant="underlined"
                            label="Primary Contact Number"
                            isInvalid={isPrimaryContactInvalid}
                            value={editedPrimaryContact}
                            className="max-w-md col-span-2 sm:col-span-4"
                            errorMessage={isPrimaryContactInvalid && "Please enter a valid 10digit number"}
                            onValueChange={setEditedPrimaryContact}
                        />
                        <Input
                            type="text"
                            variant="underlined"
                            label="Secondary Contact Number"
                            isClearable
                            value={editedSecondaryContact}
                            isInvalid={isSecondaryContactInvalid}
                            errorMessage={isSecondaryContactInvalid && "Please enter a valid 10digit number"}
                            className="max-w-md col-span-2 sm:col-span-4"
                            onValueChange={setEditedSecondaryContact}
                        />
                        <Select
                            isRequired
                            label="Blood Group"
                            defaultSelectedKeys={[editedBloodGroup]}
                            variant="underlined"
                            className="max-w-md col-span-2 sm:col-span-4"
                            onChange={e => setEditedBloodGroup(e.target.value)}>
                            {DD_BLOOD_GROUP.map(data =>
                                <SelectItem key={data.label} value={data.value}>
                                    {data.label}
                                </SelectItem>
                            )}
                        </Select>
                        <Select
                            isRequired
                            label="Gender"
                            defaultSelectedKeys={[editedGender]}
                            variant="underlined"
                            className="max-w-md col-span-2 sm:col-span-4"
                            onChange={e => setEditedGender(e.target.value)}>
                            {DD_GENDER.map(data =>
                                <SelectItem key={data.label} value={data.value}>
                                    {data.label}
                                </SelectItem>
                            )}
                        </Select>
                        {MEMBERSHIP_STATUS_FOR_DESIGNATIONS.includes(editedMembershipStatus) &&
                            <Select
                                isRequired
                                selectionMode="multiple"
                                label="Designation"
                                selectedKeys={editedDesignation}
                                variant="underlined"
                                className="max-w-md col-span-2 sm:col-span-4"
                                onSelectionChange={setEditedDesignation}
                            >
                                {DD_DESIGNATIONS.map(data =>
                                    <SelectItem key={data.label} value={data.value}>
                                        {data.label}
                                    </SelectItem>
                                )}
                            </Select>}
                        <Input
                            isRequired
                            type="text"
                            variant="underlined"
                            value={editedProfileImageUrl}
                            label="Profile Image Url"
                            className="max-w-md col-span-2 sm:col-span-4"
                            onValueChange={setEditedProfileImageUrl}
                        />
                        <Input
                            isRequired
                            type="text"
                            variant="underlined"
                            value={editedAddress}
                            label="Address"
                            className="col-span-full"
                            onValueChange={setEditedAddress}
                        />
                        {!MEMBERSHIP_STATUS_FOR_DESIGNATIONS.includes(editedMembershipStatus) && <>
                            <Divider className="mt-8 mb-2 col-span-full"/>
                            <Select
                                isRequired
                                selectionMode="multiple"
                                label="Reason for leaving/termination."
                                variant="underlined"
                                className="max-w-md col-span-2 sm:col-span-4"
                                onChange={e => setMemberTerminationReason(e.target.value)}>
                                {DD_TERMINATION_REASON.map(data =>
                                    <SelectItem key={data.label} value={data.value}>
                                        {data.label}
                                    </SelectItem>
                                )}
                            </Select>
                            <Textarea
                                isRequired
                                label="Explation"
                                placeholder="Please mention a detailed explation if the member has broken code of conduct"
                                className="col-span-8"
                                onValueChange={setMemberTerminationExplanation}
                            />
                        </>}
                    </div>

                </CardBody>
                <CardFooter className="flex flex-wrap gap-4 justify-end">
                    <Button type="button" color="default" variant="faded" onClick={() => closeMemberEdit()}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="ghost"
                            isLoading={editedMembershipDataStatus === "pending"}>
                        Submit
                    </Button>
                </CardFooter>
            </form>
        </Card>
    );
}