import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    Input,
    Select,
    SelectItem,
    Textarea
} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectClubsList} from "../../redux/slice/clubDataSlice";
import {
    getNewReportStatus,
    getProjectsForAmbassadorialStatus,
    selectSelectedReport,
    getSelectedReportStatus,
    selectProjectsForAmbassadorial,
    setProjectsForAmbassadorial,
    setProjectsForAmbassadorialStatus, setSelectedReportStatus,
    setSelectedReport, selectMoMForAmbassadorial, getMoMForAmbassadorialStatus,
} from "../../redux/slice/reportingSlice";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {getMembershipDataStatus, selectMembershipData} from "../../redux/slice/membershipSlice";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {getMembershipData} from "../../../services/MembershipService";
import {
    apiCallForNewAmbassadorialReport
} from "../../../services/AmbassadorialServices";
import {DD_AMBASSADORIAL_TYPE} from "../../../constants/dropdownList";
import {getProjectsListForAmbassadorial} from "../../../services/ProjectsServices";
import {getMeetingsList, getMoMListForAmbassadorial} from "../../../services/MeetingsServices";

export default function ReportAmbassadorial() {
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const clubsList = useSelector(selectClubsList);

    const activeMembersList = useSelector(selectMembershipData).filter(members => members.membershipStatus === "Active");
    const membershipStatus = useSelector(getMembershipDataStatus);

    const projectsList = useSelector(selectProjectsForAmbassadorial);
    const projectsListStatus = useSelector(getProjectsForAmbassadorialStatus);

    const moMList = useSelector(selectMoMForAmbassadorial);
    const moMListStatus = useSelector(getMoMForAmbassadorialStatus);

    const editReport = useSelector(selectSelectedReport);
    const editedReportStatus = useSelector(getSelectedReportStatus);

    const newReportStatus = useSelector(getNewReportStatus);

    const navigate = useNavigate();

    // For PROJECTS n MEETINGS
    const [selectedClub, setSelectedClub] = useState(editReport?.projectBy ? editReport.projectBy : []);
    const [selectedEventId, setSelectedEventId] = useState(editReport?.projectId ? editReport.projectId : []);

    const [eventBy, setEventBy] = useState(editReport?.projectBy ? editReport.projectBy : "");
    const [eventName, setEventName] = useState(editReport?.eventName ? editReport.eventName.replace("Exchange form R.I.D.", "") : "");

    const [isDraftReport, setIsDraftReport] = useState(editReport?.adminData?.reportStatus === "Draft");
    const [supportDocumentsUrl, setSupportDocumentsUrl] = useState(editReport?.supportDocumentsLink ? editReport.supportDocumentsLink : '');
    const [ambassadorialType, setAmbassadorialType] = useState(editReport?.ambassadorialType ? new Set([editReport.ambassadorialType]) : new Set([]));
    const [reportSummary, setReportSummary] = useState(editReport?.reportSummary ? editReport.reportSummary : '');

    const [attendees, setAttendees] = useState(editReport?.membersAttendance ? new Set(editReport.membersAttendance.map(String)) : new Set([]));

    useEffect(() => {
        if (window.location.pathname.toString().includes("/edit-report") && (null === editReport || undefined === editReport))
            navigate("..");

        if (membershipStatus === "idle")
            getMembershipData(dispatch, user);

        // if (null !== editReport && undefined !== editReport?.projectId)
        //     apiCallForGettingProjectNamesForSelectedClub(dispatch, user, editReport.projectBy);

    }, []);

    function clearTaskAndCloseAmbassadorialScreen() {
        dispatch(setSelectedReport({
            report: null,
            reportType: "Ambassadorial"
        }));
        dispatch(setSelectedReportStatus('idle'));
        navigate("..");
    }

    function checkAmbassadorialTypeAndGetList(clubCode) {
        if (ambassadorialType.keys().next().value === "Project")
            getProjectsListForAmbassadorial(dispatch, user, clubCode);
        else
            getMoMListForAmbassadorial(dispatch, user, clubCode);
    }

    async function submitForm(e) {
        e.preventDefault();

        let requestBody = {
            isDraft: false,
            supportDocumentsLink: supportDocumentsUrl,
            reportSummary: reportSummary.trim(),
            ambassadorialType: ambassadorialType.keys().next().value,
            membersAttendance: [...attendees].map(Number),
        }

        if (requestBody.ambassadorialType === "Project") {
            requestBody.eventBy = selectedClub.trim();
            requestBody.eventName = projectsList.find(data => data.projectId === selectedEventId).name;
            requestBody.eventId = selectedEventId;
        } else if (requestBody.ambassadorialType === "Meeting") {
            requestBody.eventBy = selectedClub.trim();
            let momData = moMList.find(data => data.meetingId === selectedEventId);
            requestBody.eventName = momData.meetingType + " : " + new Date(momData.endTime).toLocaleDateString();
            requestBody.eventId = selectedEventId;
        } else if (requestBody.ambassadorialType === "PIS") {
            requestBody.eventBy = eventBy.trim().replaceAll(" ", " ");
            requestBody.eventName = eventName.trim().replaceAll(" ", " ");
        } else {
            requestBody.eventBy = eventBy.trim().replaceAll(" ", " ");
            requestBody.eventName = "Exchange form R.I.D. " + eventName.trim().replaceAll(" ", " ");
        }

        // if (null === editReport && undefined === editReport?.ambassadorialId)
        apiCallForNewAmbassadorialReport(dispatch, user, requestBody, navigate);
        // else
        //     apiCallForUpdatingAmbassadorialReport(dispatch, user, requestBody, editReport.ambassadorialId, navigate)
    }

    return (
        <Card className="mt-10 p-4">
            <ToastContainer/>
            <form onSubmit={submitForm}>
                <CardHeader className="text-xl font-medium">
                    {null !== editReport
                        ? "Edit Ambassadorial Report : " + editReport.ambassadorialId
                        : " New Ambassadorial Report"
                    }
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                        <Select
                            isRequired
                            items={DD_AMBASSADORIAL_TYPE}
                            label="Ambassadorial Type"
                            variant="underlined"
                            className="max-w-md"
                            selectedKeys={ambassadorialType}
                            onSelectionChange={setAmbassadorialType}>
                            {type => <SelectItem key={type.value} value={type.value}>
                                {type.label}
                            </SelectItem>}
                        </Select>

                        <Input isRequired
                               type="text"
                               variant="underlined"
                               label="Supporting Documents Link"
                               value={supportDocumentsUrl}
                               onValueChange={setSupportDocumentsUrl}
                               className="max-w-lg"
                        />
                    </div>

                    {/*     COMPONENT IF TYPE IS PROJECT OR MEETING     */}
                    {(ambassadorialType.keys().next().value === "Project" || ambassadorialType.keys().next().value === "Meeting") &&
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center">
                            <Select
                                isRequired
                                items={clubsList}
                                label="Club Name"
                                defaultSelectedKeys={selectedClub.length > 0 ? [selectedClub] : []}
                                variant="underlined"
                                className="max-w-md"
                                onChange={e => {
                                    setSelectedClub(e.target.value);
                                    checkAmbassadorialTypeAndGetList(e.target.value)
                                }}>
                                {clubDataListItems => <SelectItem key={clubDataListItems.clubCode}
                                                                  textValue={clubDataListItems.name}>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex flex-col">
                                            <span className="text-small">{clubDataListItems.name}</span>
                                            <span
                                                className="text-tiny text-default-400">Zone {clubDataListItems.zone}</span>
                                        </div>
                                    </div>
                                </SelectItem>}
                            </Select>

                            <Select
                                isRequired
                                isDisabled={ambassadorialType.keys().next().value === "Project" ? projectsList.length === 0 : moMList.length === 0}
                                isLoading={ambassadorialType.keys().next().value === "Project" ? projectsListStatus === "pending" : moMListStatus === "pending"}
                                items={ambassadorialType.keys().next().value === "Project" ? projectsList : moMList}
                                label="Event Name"
                                variant="underlined"
                                className="max-w-md"
                                defaultSelectedKeys={selectedEventId.length > 0 ? [selectedEventId] : []}
                                onChange={e => setSelectedEventId(e.target.value)}>
                                {listItems =>
                                    <SelectItem
                                        key={ambassadorialType.keys().next().value === "Project" ? listItems.projectId : listItems.meetingId}
                                        textValue={ambassadorialType.keys().next().value === "Project" ? listItems.name : listItems.meetingType}>
                                        <div className="flex gap-2 items-center">
                                            <div className="flex flex-col">
                                                <span className="text-small">
                                                    {ambassadorialType.keys().next().value === "Project"
                                                        ? listItems.name
                                                        : listItems.meetingType}
                                                </span>
                                                {ambassadorialType.keys().next().value === "Meeting" &&
                                                    <span className="text-tiny text-default-400">
                                                        Date : {new Date(listItems.endTime).toLocaleDateString()}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </SelectItem>
                                }
                            </Select>

                            <Input
                                isDisabled
                                type="text"
                                variant="underlined"
                                label="Event Id"
                                value={selectedEventId}
                                className="max-w-md"
                            />
                        </div>}

                    {/*     COMPONENT IF TYPE IS PIS     */}
                    {ambassadorialType.keys().next().value === "PIS" &&
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Event By Partner"
                                className="max-w-md"
                                value={eventBy}
                                onValueChange={setEventBy}
                            />
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Event Name"
                                className="max-w-md"
                                value={eventName}
                                onValueChange={setEventName}
                            />
                        </div>}

                    {/*     COMPONENT IF TYPE IS LETTER HEAD     */}
                    {ambassadorialType.keys().next().value === "Letter Head" &&
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Exchange with Club"
                                startContent={
                                    <div className="pointer-events-none flex items-center">
                                        <span className="text-default-400 text-small">RC</span>
                                    </div>
                                }
                                className="max-w-md"
                                value={eventBy}
                                onValueChange={setEventBy}
                            />
                            <Input
                                isRequired
                                type="number"
                                variant="underlined"
                                label="District Number"
                                startContent={
                                    <div className="pointer-events-none flex items-center">
                                        <span className="text-default-400 text-small">R.I.D.</span>
                                    </div>
                                }
                                className="max-w-md"
                                value={eventName}
                                onValueChange={setEventName}
                            />
                        </div>}

                    {/*<Checkbox className="mt-4" isSelected={isDraftReport} onValueChange={setIsDraftReport}>*/}
                    {/*    Save report as Draft?*/}
                    {/*</Checkbox>*/}
                    {ambassadorialType.keys().next().value === "PIS" && <Textarea
                        isRequired
                        variant="underlined"
                        label="Report Summary"
                        placeholder="Please mention in breif what happend"
                        className="mt-2"
                        value={reportSummary}
                        onValueChange={setReportSummary}
                    />}
                    <Select
                        isRequired
                        selectionMode="multiple"
                        items={activeMembersList}
                        isLoading={membershipStatus === "pending"}
                        isDisabled={activeMembersList.length === 0}
                        label="Attendees"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={attendees}
                        onSelectionChange={setAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                </CardBody>
                <CardFooter className="flex gap-4 justify-end">
                    <Button color="default" variant="bordered"
                            onClick={() => clearTaskAndCloseAmbassadorialScreen()}>
                        Cancel
                    </Button>

                    <Button color="success" variant="ghost" type="submit"
                            isLoading={newReportStatus === "pending" || editedReportStatus === "pending"}>
                        {isDraftReport ? 'Save as Draft' : 'Submit'}
                    </Button>
                </CardFooter>
            </form>
        </Card>
    );
}